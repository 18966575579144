// components/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-black text-gray-400">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        {/* Logo and Description */}
        <div className="flex flex-col items-center text-center">
          <img
            src="/images/logo-silver-bull.png"
            alt="V&S Trades Logo"
            className="h-16 w-auto mb-4"
          />
          <p className="text-gray-500 max-w-md">
            Committed to providing top-quality fabrication and millwright services with a rugged touch of excellence.
          </p>
        </div>

        {/* Links and Contact Info */}
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Navigation Links */}
          <div className="text-center md:text-left">
            <h3 className="text-lg font-bold text-gray-100 uppercase mb-4">
              Quick Links
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="hover:text-gray-200">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-gray-200">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:text-gray-200">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/projects" className="hover:text-gray-200">
                  Projects
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-gray-200">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="text-center md:text-left">
            <h3 className="text-lg font-bold text-gray-100 uppercase mb-4">
              Contact Us
            </h3>
            <p className="text-gray-500">
              Phone:{' '}
              <a href="tel:+18063468702" className="hover:text-gray-200">
                (806) 346-8702
              </a>
            </p>
            <p className="text-gray-500">
              Email:{' '}
              <a href="mailto:sales@vstrades.net" className="hover:text-gray-200">
                sales@vstrades.net
              </a>
            </p>
            <p className="text-gray-500">
              Address:
              <br />
              Hereford, TX 79045 USA
            </p>
          </div>
        </div>

        {/* Divider */}
        <div className="mt-8 border-t border-gray-700"></div>

        {/* Footer Bottom */}
        <div className="mt-4 flex flex-col md:flex-row justify-center items-center text-sm text-center">
          <p className="text-gray-500">
            &copy; {new Date().getFullYear()} V&S Trades. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
