// App.jsx
import React, { Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';
import { AnimatePresence } from 'framer-motion';
import ScrollToTop from './components/ScrollToTop';
import PageWrapper from './components/PageWrapper';

// Lazy-loaded Pages
const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const Services = React.lazy(() => import('./pages/Services'));
const Projects = React.lazy(() => import('./pages/Projects'));
const Contact = React.lazy(() => import('./pages/Contact'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

function App() {
  const location = useLocation();

  return (
    <div className="flex flex-col min-h-screen">
      {/* Global Meta Tags */}
      <Helmet>
        <title>V&S Trades</title>
        <meta
          name="description"
          content="V&S Trades offers top-notch fabrication and millwright services."
        />
        {/* Add additional global meta tags as needed */}
      </Helmet>

      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <main id="main-content" className="flex-grow">
        {/* Scroll to Top on Route Change */}
        <ScrollToTop />
        {/* Suspense for Lazy Loading */}
        <Suspense
          fallback={
            <div className="flex items-center justify-center h-screen">
              <div className="text-gray-100">Loading...</div>
            </div>
          }
        >
          {/* AnimatePresence for Route Transitions */}
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
              {/* Route Definitions with Animations */}
              <Route
                path="/"
                element={
                  <PageWrapper>
                    <Home />
                  </PageWrapper>
                }
              />
              <Route
                path="/about"
                element={
                  <PageWrapper>
                    <About />
                  </PageWrapper>
                }
              />
              <Route
                path="/services"
                element={
                  <PageWrapper>
                    <Services />
                  </PageWrapper>
                }
              />
              <Route
                path="/projects"
                element={
                  <PageWrapper>
                    <Projects />
                  </PageWrapper>
                }
              />
              <Route
                path="/contact"
                element={
                  <PageWrapper>
                    <Contact />
                  </PageWrapper>
                }
              />
              {/* 404 Not Found Route */}
              <Route
                path="*"
                element={
                  <PageWrapper>
                    <NotFound />
                  </PageWrapper>
                }
              />
            </Routes>
          </AnimatePresence>
        </Suspense>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default App;
