// components/Navbar.jsx
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const menuItems = [
    { name: 'About Us', to: '/about' },
    { name: 'Services', to: '/services' },
    { name: 'Projects', to: '/projects' },
    { name: 'Contact', to: '/contact' },
  ];

  // Animation variants for the menu
  const menuVariants = {
    open: {
      opacity: 1,
      height: 'auto',
      transition: {
        duration: 0.3,
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
        when: 'afterChildren',
      },
    },
  };

  // Animation variants for menu items
  const menuItemVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: -20 },
  };

  // Animation variants for hamburger icon
  const topBarVariants = {
    open: { rotate: 45, y: 7 },
    closed: { rotate: 0, y: 0 },
  };

  const middleBarVariants = {
    open: { opacity: 0 },
    closed: { opacity: 1 },
  };

  const bottomBarVariants = {
    open: { rotate: -45, y: -7 },
    closed: { rotate: 0, y: 0 },
  };

  return (
    <nav className="bg-black text-gray-100 fixed w-full z-20 top-0 left-0 border-b border-gray-700">
      {/* Skip to Content Link */}
      <a href="#main-content" className="sr-only focus:not-sr-only">
        Skip to main content
      </a>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* Logo and Company Name */}
          <div className="flex items-center">
            <NavLink to="/" className="flex items-center">
              <img
                src="/images/logo-silver-bull.png"
                alt="V&S Trades Logo"
                className="h-8 w-auto sm:h-10 mr-2 sm:mr-3"
              />
              <span className="text-xl sm:text-2xl font-extrabold tracking-wide">
                V&S Trades
              </span>
            </NavLink>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-6">
            {menuItems.map((item) => (
              <NavLink
                key={item.name}
                to={item.to}
                className={({ isActive }) =>
                  isActive
                    ? 'border-b-2 border-gray-100 pb-2'
                    : 'hover:text-gray-300 transition duration-200'
                }
              >
                {item.name}
              </NavLink>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setMenuOpen(!isMenuOpen)}
              className="text-gray-100 hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
              aria-label="Toggle menu"
              aria-expanded={isMenuOpen}
            >
              {/* Hamburger Icon */}
              <div className="relative w-6 h-6">
                <motion.span
                  className="absolute top-0 left-0 w-full h-0.5 bg-gray-100"
                  variants={topBarVariants}
                  initial="closed"
                  animate={isMenuOpen ? 'open' : 'closed'}
                />
                <motion.span
                  className="absolute top-2.5 left-0 w-full h-0.5 bg-gray-100"
                  variants={middleBarVariants}
                  initial="closed"
                  animate={isMenuOpen ? 'open' : 'closed'}
                />
                <motion.span
                  className="absolute bottom-0 left-0 w-full h-0.5 bg-gray-100"
                  variants={bottomBarVariants}
                  initial="closed"
                  animate={isMenuOpen ? 'open' : 'closed'}
                />
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            className="md:hidden bg-black overflow-hidden"
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            <div className="px-2 pt-2 pb-3 space-y-1">
              {menuItems.map((item) => (
                <motion.div
                  key={item.name}
                  variants={menuItemVariants}
                  className="border-b border-gray-700"
                >
                  <NavLink
                    to={item.to}
                    className="block text-gray-100 hover:text-gray-300 px-3 py-2 text-base font-medium"
                    onClick={() => setMenuOpen(false)}
                  >
                    {item.name}
                  </NavLink>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}

export default Navbar;
